import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  isAddTag = false;
  isAddAssetTag = false;

  name: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationComponent>) {
      if(data){
    this.message = data.message || this.message;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      this.isAddTag = data.isAddTag || this.isAddTag;
      this.isAddAssetTag = data.isAddAssetTag || this.isAddAssetTag;
    }
      }
  }

  ngOnInit(): void {
      
  }

  onConfirmClick(data: any): void {
    this.dialogRef.close(data);
  }

}
