// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-tab-body-content {
  overflow: hidden !important;
}

.img-description {
 
  box-sizing: border-box;
  margin: 0 0 0 0;
  position: relative;
  border-bottom: 3px solid #f1f1f1;
}

.bg-color {
  background-color: #fff;
  min-height: 60px;
}

.image-tag-bg-color {
  background-color: #fff;
  min-height: 60px;
}

.align-item-tag {
  margin: 4px;
}

.box-container-shadow {
  box-shadow: 1px 1px 1px 1px #f1f1f1;
}

.heading {
  color: #165788;
  font-size: 14px;
  font-weight:500;
}

.icon-font-size {
  font-size: 18px;
}

.btn-add {
  border: none;
  background: #00518b;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
}

.tag-div-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

mat-chip {
  font-size: 12px;
  background-color: #b91919;
  display: flex;
  flex-direction: row;
}

.btn-add:hover {
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
}

.item-tag-div{
    width:75%;
    margin-left:5%;
   max-height:38px;
}
.item-tag-div-doc{
  width:75%;
  margin-left:5%;
 
}
.entity-tag-button-div {
  float: right;
  width: 23%;
  text-align: right;
}
.image-tag-button-div {
  float: right;
  width: 23%;
  margin-bottom: 15px;
}
.document-tag-button-div {
  float: right;
  
}
.iconsize .dx-icon {
  width:20px;
  height: 20px;
}

.upload-button-div {
  width: 17%;
}
.image-tag-div{
    width:80%
}


.img-container {
  width: 55%;
  float: left;
  
  border: 1px solid #ccc;
  border-radius: 5px;
  height:532px;
  margin: 6px 6px 6px 6px;
}

.tag-container {
  float: right;
 
  border-radius: 5px;
  max-height: 545px;
  width: 43%;
 
  box-shadow: 1px 1px 1px 1px #f1f1f1;
}
.document-container
{
  
  border-radius: 5px;
  max-height: 545px;
  box-shadow: 1px 1px 1px 1px #f1f1f1;

}

.asset-tag {
  top: 50px;
  overflow-x: hidden;
}

.file-upload {
  position: absolute;
  top: 52px;
  right: 30px;
}

input[type="file"] {
  position: relative;
  top: 10px;
}

.delete-ico {
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 20px;
  border-radius: 50%;
}

.thumbnail-wraper {
  width: 100%;
  float: left;
 
}

.document-thumbnail-wraper {
  width: 50%;
 
  float: left;
  
}

.button-title {
  padding-left: 50px;
  padding-right: 74px;
}



.bg-color {
  background-color: #fbfbfb;
  
}

img {
  height: 100%;
  width: 100%;
}

.img-align {
  max-width: 100%;
  max-height: 95%;
}

.sub-image {
  max-width: 100%;
  max-height: 80%;
}


.asset-area {
  overflow: hidden;
}
.document-asset-area
{
  overflow: hidden;
  height: 650px;
  overflow-y: auto;
  margin: 10px 10px 10px 10px;
}
.brdr {
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  width: 29%;
  height: 130px;
  float: left;
  margin: 5px;
  position: relative;
}

.document-brdr {
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 4px;
  width: 29%;
  height: 650px;
  float: left;
  margin: 20px;
  position: relative;
}

mat-chip {
  font-size: 12px;
  background-color: #f1f1f1;
}

.user-details {
  float: right;
  width: 60%;
}

.brdr .user-details li {
  list-style: none;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.font-weight {
  font-weight: bold;
}

.font-uploadedBy {
  float: right;
}

.sub-heading {
  color: #165788;
  font-size: 12px;
}

.entity-btn-add {
    border: none;
  background: #00518b;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
}
.image-tag-div-size {
  height: 70px;
  box-sizing: border-box;
  position: relative;
  border-top: 3px solid #f1f1f1;
  clear: both;
}
.image-tag-div-size-doc {
  height: 70px;
  box-sizing: border-box;
  position: relative;
  border: 2px solid #f1f1f1;
  clear: both;
}

.cancel-icon-cls {
  background: none;
  border: none;
  float: right;
  margin-top: -2.8%;
}

.icon-font-size {
  font-size: 18px;
}

.entity-btn-add:hover {
  box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
}

.btn-upload {
  margin: 0px 10px;
  border: none;
  background: #00518b;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
}

  .btn-upload:hover {
    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
  }

.selected, .brdr:hover {
  border: 1px solid #3f51b5;
  box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
  -webkit-box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
  -moz-box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
  border-radius: 4px;
}

.img-description {
  padding: 5px;
  box-sizing: border-box;
 
  position: relative;
  border-top: 1px solid #f1f1f1;
  border-bottom: 3px solid #f1f1f1;
}
.tab-title {
  height: 35px !important;
}


.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12);
  height: 35px!important;
  bottom: 20px!important;
}

 

.file-input {
  display: none;
}
mat-progress-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

/* ::ng-deep .mat-chip-list-wrapper {
  flex-wrap: unset !important;
  width:501px;
  overflow-x: auto;

} */

.scrollmenu{
  
 font: optional;
 
  
}
 .scrollmenu ::ng-deep .mat-chip-list-wrapper{
  
  overflow-x: auto !important
   ;
 
}

.popuptags
{
  font: optional;
}
.popuptags ::ng-deep .mat-chip-list-wrapper{
  
  flex-wrap: unset !important;
  width:501px;
  overflow-x: auto;
 
}

.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 2;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

::ng-deep #container {
  padding: 10px;
}

::ng-deep #container ul {
  list-style-type: none;
  text-align: center;
}

::ng-deep #container ul li {
  display: inline-block;
  width: 160px;
  margin: 10px;
}

::ng-deep #container ul li img {
  width: 100px;
}

::ng-deep .button-info {
  margin: 10px;
}

::ng-deep .dx-popup-content p {
  margin-bottom: 10px;
  margin-top: 0;
}

`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
