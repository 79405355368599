// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep body {
    background-color: #f1f1f1;
}
.body-container {
    width: 90%;
    background-color: #fff;
    /*padding: 10px;*/
    box-sizing: border-box;
    margin: 0 auto;
    box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.10);
    -webkit-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.10);
    -moz-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.10);
    border-radius: 6px;
}
.mat-toolbar.mat-primary {
  background: #165788!important;
}

#dataGrid {
  height: 500px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
