import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EntityAsset, Tabs } from '../model/asset.model';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { EntityService } from 'src/app/service/entityService';
import { Entity } from '../model/entity.model';
import { EntityTag } from '../model/entityTag.model';
import { AddEntityAsset } from '../model/addEntityAsset.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { AccessDetails } from '../model/acessDetails.model';
import { OracleEntity } from '../model/oracleEntity.model';
import { EntityAssetService } from '../service/entityAssetService';
import { EntityAssetTag } from '../model/entityAssetTag.model';
import { CommonService } from '../Shared/commonService';
import { HttpResponse, HttpStatusCode } from '@angular/common/http';
import { zip } from 'rxjs';
import { LoadingService } from '../loading.service';
import { MatTabChangeEvent as MatTabChangeEvent } from '@angular/material/tabs';
import DxPopup from 'devextreme/ui/popup';
import { UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { EntityCategory } from '../model/entityCategory.model';



@Component({
  selector: 'app-manage-assets',
  templateUrl: './manage-assets.component.html',
  styleUrls: ['./manage-assets.component.css']
})

export class ManageAssetsComponent implements OnInit {

  store: CustomStore;
  loading$ = this.loader.loading$;
  itemDeleteMode = 'static';
  activeLinkIndex = -1;
  popupVisible : boolean=false;
  tabs: any[];
  pagenumber:number=1;
  pagesize:number=10;
  entityTags: EntityTag[] = [];
  entityTag: EntityTag;
  tempPatchArrayObj: any;
  entityId: number;
  documentassetId:number;
  entityAssetsImages: EntityAsset[];
  addEntityAsset: AddEntityAsset;
  queryString: any;
  urlParams: any;
  entitySourceId: any;
  source: any;
  entityType: any;
  site: any;
  userSite: any;
  appRegion: any;
  nlsLanguage :string="";
  parameters: any;
  accessDetails: AccessDetails;
  accessDetailsObj: AccessDetails;
  entityAddTagBtnFlag: boolean = true;
  hideButton:boolean=false;
  oracleEntity: OracleEntity;
  newEntityTag: string[] = [];

  //#region image component variable declaration
  isVisible = false;
  entityAssetTags: EntityAssetTag[] = [];
  documententityAssetTags: EntityAssetTag[] = [];
  documentAssetTags: EntityAssetTag[] = [];
  entityAssetId: number;
  sourceData: DType[] = [];
  documentSourceData: DType[] = [];
  selectedItem: DType = new DType();
  saveButtonOptions: any;
  positionOf: string;
  tagName: string;
  closeButtonOptions: any;
  entityAssetObjlist: any;
  uploadedFiles: any;
  selectedFilename: any;
  selectedFile: any;
  entityAssetList: EntityAsset[];
  assetAddTagBtnFlag: boolean = true;
  tagAutoFill:boolean=false;
  assetUploadBtnFlag: boolean = false;
  fileName: any;
  testData: SafeUrl;
  selectedTab :string="";
  assetName:string="";
  documentURL:any; 
  nameSortOrder: string = "asc";
  public popup: DxPopup; //#endregion
  public tagOptions = {
    
    onClick: () => { this.popup.show() }
  }
  dataSource: CustomStore//any = [];

  

  readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  displayMode = 'full';

  showPageSizeSelector = true;

  showInfo = true;

  showNavButtons = true;
reshape='reshape';
  

  constructor( public loader: LoadingService,private router: Router, private cd: ChangeDetectorRef, public dialog: MatDialog, private entityService: EntityService, private commonService: CommonService, private entityAssetService: EntityAssetService,
     private sanitizer: DomSanitizer,private formBuilder :UntypedFormBuilder,private httpClient:HttpClient ) {
    this.tabs = Tabs
    this.popupVisible=false;
    this.downloadFile=this.downloadFile.bind(this);
    this.deleteClicked=this.deleteClicked.bind(this);
    this.TagsClicked=this.TagsClicked.bind(this);
    
  }
  tagsForm = this.formBuilder.group({
   
    name: ''
  });
  
  ngOnInit(): void {
    
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);
    this.entitySourceId = this.urlParams.get('entitySourceId');
    this.appRegion = this.urlParams.get('appRegion');
    this.selectedTab="Images";
    if (this.appRegion == null) {
      this.appRegion = 'AUA';
  }
    this.source = this.urlParams.get('source') + this.appRegion;
    this.entityType = this.urlParams.get('entityType');
    this.site = this.urlParams.get('site');
    this.userSite = this.urlParams.get('userSite');
   
  if (this.urlParams.get('nlsLanguage')!=null) {
    this.nlsLanguage=this.urlParams.get('nlsLanguage');
}

    this.parameters = 'entityname/' + this.entityType + '/userSite/' + this.userSite;
   
     //Parallel call 
     let entitydetails =  this.entityService.getOracleEntity(this.entityType, this.site, this.entitySourceId, this.appRegion,this.nlsLanguage);
     let access = this.entityService.getAccessdetails(this.entityType, this.userSite, this.appRegion);
    
     zip(entitydetails, access).subscribe(val => {
      this.accessDetailsObj= val[1] as AccessDetails
      this.oracleEntity=val[0] as OracleEntity
      if (this.oracleEntity.status === "S") {
      if (this.accessDetailsObj.accessLevel) {
        
            if (this.accessDetailsObj.accessLevel === 'NO_ACCESS') {
                this.router.navigate(['./custom-error']);
             }
                else {
                  if (this.accessDetailsObj.accessLevel === 'FULL_ACCESS') {
                    this.entityAddTagBtnFlag = false;
                    this.hideButton=false;
                    this.assetUploadBtnFlag=false;
                  }
                  else if(this.accessDetailsObj.accessLevel === 'READ_ACCESS')
                  {
                    this.hideButton=true;
                    this.assetUploadBtnFlag=true;
                  }
                  if (this.entitySourceId != null && this.source != null && this.entityType != null) {
                    this.entityService.getEntityCategory(this.source, this.entityType).subscribe((res: EntityCategory) => {
                      if(res.DataTypeId==2)
                      {
                        this.router.navigate(['./not-found']);
                      }
                      else
                      {
                        this.entityService.getEntitySourceAsset(this.entitySourceId, this.source, this.entityType, this.site).subscribe((res: Entity) => {
                          this.entityTags = res.entityTags;
                          this.entityId = res.entityId;
                          this.accessDetails = this.accessDetailsObj;
                          if (res.entityAssets) {
                            this.entityAssetsImages = res.entityAssets.filter(x => x.typeId == 1);
                          }
                          this.loadImagesData();
                     
                        },
                          (err: any) => {
                            if (this.oracleEntity) {
                              var entityTagListObj = this.oracleEntity.descriptionToken.split('~');
                              if (entityTagListObj) {
                                if (entityTagListObj.length > 3) {
                                  entityTagListObj = entityTagListObj.slice(0, 3);
                                }
                                this.newEntityTag = entityTagListObj;
                              }
                            }
                            this.addEntityAsset = { sourceEntityId: this.entitySourceId, source: this.source, entityType: this.entityType, site: this.site, entityTags: this.newEntityTag };
                            this.entityService.addEntity(this.addEntityAsset).subscribe((res: Entity) => {
                              this.entityTags = res.entityTags;
                              this.entityId = res.entityId;
                              this.accessDetails = this.accessDetailsObj;
                              if (res.entityAssets) {
                                this.entityAssetsImages = res.entityAssets.filter(x => x.typeId == 1);
                              }
                              this.loadImagesData();
                          
                            })
                          })
                        }
                      }
                      ,
                      (err: any) => {
                        this.router.navigate(['./not-found']);
                      }

                    )
                  }
                }
              }
              else {
                this.router.navigate(['./custom-error']);
              }
            }
               else {
            this.router.navigate(['./not-found']);
          }
           
          });
         
        }

  onChange(event: MatTabChangeEvent) {
    this.selectedTab = event.tab.textLabel;
   
      if(this.selectedTab==="Documents")
      {
         this.loadDocumentsData();

        }
  }


  GetFileSize(e:any)
  {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(e.size, 10) || 0;

      while(n >= 1024 && ++l){
          n = n/1024;
      }
  
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);

  }


  calculateSummary(options: any) {
    // Calculating "customSummary1"
    if(options.name == "customSummary1") {
      options.totalValue=10;
    }
  }


  GetDocType(e:any) {

    var doctype="";
  
    switch (e) {
      case "docx":
        doctype="data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
          break;
      case "pdf":
        doctype="data:application/pdf;base64,";
          break;
      case "mp4":
       doctype="data:video/mp4;base64,";
        break;
     
    }
return doctype;

}

  GetIconURL(e:any) {

            var str=e.name.split('.');
            var docExtension=str[1];
            var docIconUrl="";
          
            switch (docExtension) {
              case "docx":
                docIconUrl="../assets/images/docx_icon.png";
                  break;

              case "pdf":
                docIconUrl="../assets/images/pdf-icon.jpg";
                  break;

              case "xlsx":
                docIconUrl="../assets/images/excel-icon.png";
                  break;

              case "mp4":
                docIconUrl="../assets/images/video-image.png";
                break;

              default:
                docIconUrl="../assets/images/other-doc.png";
                  break;
            }
    return docIconUrl;

  }

  loadDocumentsData() {
    let that=this;
    let sortorder:string="asc";
    let sortfield:string="name";
    this.dataSource = new CustomStore({
      key: 'id',
    
      load(loadOptions: any) { 
      
        that.pagenumber=Math.floor(loadOptions.skip / loadOptions.take) + 1
        that.pagesize=loadOptions.take;
        if(loadOptions.sort!=null)
        {
        if(loadOptions.sort[0].desc)
        {
          sortorder="desc";

        }
        else
        {
          sortorder="asc";
        }
        sortfield=loadOptions.sort[0].selector;
      }
       return  that.entityAssetService.getEntityAssetByEntityId(that.entityId,that.pagenumber,that.pagesize,sortfield,sortorder).toPromise().then((res) => {
        
        return {  
          data: res.entityAsset,  
          totalCount: res.totalCount
          
      } 
          });
         
      }});
    }
   

  onSubmit(): void {
    
    const data = { "EntityAssetTagId": "0", "EntityAssetId": this.documentassetId, "TagName": this.tagsForm['value'].name };
    this.entityAssetService.UpdateEntityAssetTag(data).subscribe((res: EntityAssetTag) => {
     
          this.documentAssetTags.push(res);
      });
   
    this.tagsForm.reset();
    
  }



  //#region Image component code goes here
  loadImagesData() {
    this.entityAssetObjlist = this.entityAssetsImages;
    if (this.entityAssetObjlist) {
      if (this.entityAssetObjlist.length > 0) {
        
        for (let j = 0; j < this.entityAssetObjlist.length; j++) {
          this.entityService.getSingleImageAsset(this.entityAssetObjlist[j].sampleUrl).then((res) => {
            var data = 'data:image/png;base64,' + res.sourceData;
            var Item: DType = new DType();
            Item.originalUrl = this.entityAssetObjlist[j].originalUrl;
            Item.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
            Item.CreatedBy = this.entityAssetObjlist[j].createdBy;
            if (this.entityAssetObjlist[j].createdDate) {
              const date = this.entityAssetObjlist[j].createdDate;
              Item.CreatedDate = new Date(date);
            }
            Item.entityAssetId = this.entityAssetObjlist[j].id;
            Item.name=this.entityAssetObjlist[j].name;
            
            this.sourceData.push(Item);
            if (this.sourceData.length == 1) {
              this.selectedItem = this.sourceData[0];
              this.displayData(this.selectedItem);
            }
          })
            .catch((error) => {
            });
        }
      }
    }
    this.accessDetailsObj = this.accessDetails;
    if (this.accessDetailsObj) {
      if (this.accessDetailsObj.accessLevel === 'FULL_ACCESS' || this.accessDetailsObj.accessLevel === 'UPDATE_ACCESS') {
        if (this.entityAssetObjlist) {
          if (this.entityAssetObjlist.length > 0) {
            this.assetAddTagBtnFlag = false;
            this.assetUploadBtnFlag = false;
            this.hideButton=false;
          }
         
          
        }
      }
    }
  }


async TagsClicked (e:any)
{
  if (e.row.data.id) {
    this.documentAssetTags=[];
    this.documentassetId=e.row.key;
    (await this.entityAssetService.getEntityAsset(e.row.data.id).subscribe((res: EntityAsset) => {
      this.documentAssetTags=res.entityAssetTags;
  
   this.popupVisible=true;
  }));
  
}
}

async downloadFile(e: any)
 {
  var str=e.row.data.name.split('.');
  var docExtension=str[1];
  
  var Item: DType = new DType();
  Item.sourceData= (await this.entityService.getSingleImageAsset(e.row.data.originalUrl)).sourceData;
   
  const byteArray= new Uint8Array(
    atob(Item.sourceData).split('').map((char)=>char.charCodeAt(0))
  );

   const file= new Blob([byteArray],{type:this.GetDocType(docExtension)});
   const fileURL= URL.createObjectURL(file);
   let fileName=e.row.data.name;
   let link=document.createElement('a');
   link.download=fileName;
   link.target="_blank";
   link.href=fileURL;
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 }

  deleteClicked (e:any) {
  
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    this.tempPatchArrayObj.push(this.commonService.createPatchArray("Id", e.row.data.id));
    this.tempPatchArrayObj.push(this.commonService.createPatchArray("Active", 0));
    for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
      patchArrayObj.push(this.tempPatchArrayObj[i][0]);
    }
    this.entityAssetService.getPatchEntityAsset(e.row.data.id, patchArrayObj, this.appRegion).subscribe((res: EntityAsset) => {
      
      this.dataSource.push([{ type: "remove", key: e.row.data.id }]);
      
    });
  
}
  loadEntityImageAsset(entity: any) {

    this.isVisible = true;
    this.entityAssetId = entity.entityAssetId;
  
    if (entity.entityAssetId) {
      this.entityAssetService.getEntityAsset(entity.entityAssetId).subscribe((res: EntityAsset) => {
        this.entityAssetTags=[];
        this.entityAssetTags=res.entityAssetTags;
         
      });
    }
    
  }

   displayData(entity: any) {
    if (!this.isDeleteClicked) {
      this.entityService.getSingleImageAsset(entity.originalUrl).then((res) => {
        var data = 'data:image/png;base64,' + res.sourceData;
        var Item: DType = new DType();
        Item.imgUrl = this.sanitizer.bypassSecurityTrustUrl(data);
        entity.imgUrl = Item.imgUrl;
        this.selectedItem = entity;
       this.loadEntityImageAsset(entity);
      });
    }
  }
 

  addDocumentTag(entityasset:any){
    const dialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      data: {
        isAddAssetTag: true,
        buttonText: {
          ok: 'Add Tag',
          cancel: 'Cancel'
        }
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (result !== false) {
        const data = { "EntityAssetTagId": "0", "EntityAssetId": entityasset.entityAssetId, "TagName": result };
        this.entityAssetService.UpdateEntityAssetTag(data).subscribe((res: EntityAssetTag) => {
         
          for (var value of this.documentSourceData) {
            if(value.entityAssetId==res.entityAssetId)
            {
              value.entityAssetsTags.push(res);
            }
          }
          
     
        });
      }
    });


  }

  addTag() {
    const dialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      data: {
        isAddAssetTag: true,
        buttonText: {
          ok: 'Add Tag',
          cancel: 'Cancel'
        }
      }
    });



    dialog.afterClosed().subscribe(result => {
      if (result !== false) {
        const data = { "EntityAssetTagId": "0", "EntityAssetId": this.entityAssetId, "TagName": result };
        this.entityAssetService.UpdateEntityAssetTag(data).subscribe((res: EntityAssetTag) => {
         
          this.entityAssetTags.push(res);
         
        });
      }
    });
  }

  removeDocumentTag(Tag: any)
  {
    this.entityAssetService.DeleteEntityAssetTag(Tag.entityAssetTagId).subscribe((res: EntityAssetTag) => {
      const index = this.documentAssetTags.indexOf(Tag);
      if (index >= 0) {
        this.documentAssetTags.splice(index, 1);
      }
    });

  }

  remove(Tag: any): void {
    const dialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      data: {
        isAddAssetTag: false,
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    })
    dialog.afterClosed().subscribe(result => {
      if (result !== false) {
        this.entityAssetService.DeleteEntityAssetTag(Tag.entityAssetTagId).subscribe((res: EntityAssetTag) => {
          const index = this.entityAssetTags.indexOf(Tag);
          if (index >= 0) {
            this.entityAssetTags.splice(index, 1);
          }
        });
      }
    })
  }

  isDeleteClicked: boolean;
  deleteAsset(item: any) {
    if (!this.assetAddTagBtnFlag) {
      this.isDeleteClicked = true;
      this.tempPatchArrayObj = [];
      var patchArrayObj = [];
      this.tempPatchArrayObj.push(this.commonService.createPatchArray("Id", item.entityAssetId));
      this.tempPatchArrayObj.push(this.commonService.createPatchArray("Active", 0));
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
      this.entityAssetService.getPatchEntityAsset(item.entityAssetId, patchArrayObj, this.appRegion).subscribe((res: EntityAsset) => {
        const index = this.sourceData.indexOf(item);
        if (index >= 0) {
          this.sourceData.splice(index, 1);
        }
        this.isDeleteClicked = false;

        this.selectedItem = this.sourceData[0];
        if (this.sourceData[0]) {
          this.entityAssetId = this.sourceData[0].entityAssetId;
          if (this.entityAssetId) {
            this.entityAssetService.getEntityAsset(this.entityAssetId).subscribe((res: EntityAsset) => {
              this.entityAssetTags = res.entityAssetTags;
            });
          }
          else {
            this.entityAssetTags = [];
            this.sourceData = [];
            this.selectedItem = new DType;
            this.assetAddTagBtnFlag = true;
          }
        }
        else {
          this.entityAssetTags = [];
          this.sourceData = [];
          this.selectedItem = new DType;
          this.assetAddTagBtnFlag = true;
        }
      });
    }
  }

  
   
 BytesLength(x: any){
  const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let l = 0, n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l){
      n = n/1024;
  }
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}
 

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      let sizeInBytes: number = file.size;
      var str=file.name.split('.');
      var docExtension=str[1];
      var inValidFormats = ['exe','bat','com','inf','ipa','osx','pif','run','wsh','sql'];
      
      if(sizeInBytes>environment.FileUploadSize)
      {
        this.dialog.open(ConfirmationComponent, {
          width: '400px',
          data: {
          
        buttonText: {
          ok: 'OK',
          cancel: 'Cancel'
        },
            
            message: 'Maximum of 20 MB file upload is allowed',
          }
        });

      }
      else if(inValidFormats.find(x=>x===docExtension))
      {
        this.dialog.open(ConfirmationComponent, {
          width: '400px',
          data: {
          
        buttonText: {
          ok: 'OK',
          cancel: 'Cancel'
        },
            
            message: 'File type with extension '+docExtension+' not allowed',
          }
        });

      }
      else
      {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append("file", file);
      this.entityService.uploadEntityAsset(this.entityId, formData, this.appRegion).subscribe((res: EntityAsset) => {
        var Item: DType = new DType();
        Item.entityAssetId = res.id;
        if(this.selectedTab==="Documents")
      {
        if(res.typeId==2 || res.typeId==3)
        {
         this.dataSource.push([{ type: "insert", data: res, index: -1 }]);
          
        }
      }
      else if(this.selectedTab==="Images")
      {
        if(res.typeId==1)
        {
        var data = 'data:image/png;base64,' + res.sourceData;
        Item.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        Item.originalUrl = res.originalUrl;
        Item.CreatedBy = res.createdBy;
        Item.CreatedDate = new Date(res.createdDate.toString());
        
        Item.name=res.name;
        this.sourceData.push(Item);
        this.selectedItem = this.sourceData[0];
        if (this.accessDetailsObj.accessLevel === 'FULL_ACCESS' || this.accessDetailsObj.accessLevel === 'UPDATE_ACCESS') {
          this.assetAddTagBtnFlag = false;
        }
      }
      }
        if (!this.entityAssetId) {
          this.entityAssetId = this.selectedItem.entityAssetId;
        }

        const t = { "EntityAssetTagId": "0", "EntityAssetId": Item.entityAssetId, "TagName": this.entitySourceId };
        this.entityAssetService.UpdateEntityAssetTag(t).subscribe((res: EntityAssetTag) => { //add entity source id as image asset tag
        });
      },
       (error: Response) => {  
         if(error.status === HttpStatusCode.RangeNotSatisfiable)  
          this.dialog.open(ConfirmationComponent, {
           width: '400px',
           data: {
           
         buttonText: {
           ok: 'OK',
           cancel: 'Cancel'
         },
             isAddAssetTag: false,
             isAddTag:false,
             message: 'To add additional images please delete some of the existing images',
           }
         });
         if(error.status === HttpStatusCode.BadRequest || error.status === HttpStatusCode.NotFound
          || error.status === HttpStatusCode.UnprocessableEntity)  
          this.dialog.open(ConfirmationComponent, {
           width: '400px',
           data: {
           
         buttonText: {
           ok: 'OK',
           cancel: 'Cancel'
         },
             isAddAssetTag: false,
             isAddTag:false,
             message: 'There was a problem uploading, please contact an administrator and try again later',
           }
         });
        } );
    }
  }
  }
  //#endregion
}

export class DType {
  IconUrl:SafeUrl;
  imgUrl: SafeUrl;
  CreatedBy: any;
  CreatedDate: Date;
  entityAssetId: any;
  originalUrl: any;
  name: any;
  sourceData : any;
  entityAssetsTags: EntityAssetTag[];
  doctype:any;
  docextension:any;
  size: any;
}


