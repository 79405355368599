import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
//import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { MatSliderModule as MatSliderModule } from '@angular/material/slider';
import { MatIconModule} from '@angular/material/icon';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatFormFieldModule as MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule as MatInputModule} from '@angular/material/input';
import { SearchComponent } from './search/search.component';
import { MatAutocompleteModule as MatAutocompleteModule} from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule as MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatDialogModule as MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule as MatChipsModule } from '@angular/material/chips';
import { MatButtonModule as MatButtonModule } from '@angular/material/button';
//import { MatFileUploadModule } from 'angular-material-fileupload';
import { ManageAssetsComponent } from './manage-assets/manage-assets.component';
import { VideosComponent } from './manage-assets/videos/videos.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import { environment } from 'src/environments/environment';
import { AudioComponent } from './manage-assets/audio/audio.component';
import { DocumentsComponent } from './manage-assets/documents/documents.component';
import { ConfirmationComponent } from './manage-assets/confirmation/confirmation.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { CustomErrorComponent } from './custom-error/custom-error.component';
import { NotFoundComponent } from './Shared/not-found/not-found.component';
import { NetworkInterceptor } from './network.interceptor';
import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ViewAssetComponent } from './view-asset/view-asset.component';

import { DxDataGridModule, DxListModule,DxPopupModule,DxButtonModule, DxTemplateModule} from 'devextreme-angular';



const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      redirectUri: environment.redirectUrl,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId + '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
        },
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(environment.WEBAPIURL, environment.scope);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"]
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    SearchComponent,
    ManageAssetsComponent,
    VideosComponent,
    AudioComponent,
    DocumentsComponent,
    ConfirmationComponent,
    CustomErrorComponent,
    NotFoundComponent,
    ViewAssetComponent
  ],
  imports: [
    BrowserModule,
    
    TextFieldModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatIconModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatButtonModule,
    
    MatTabsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatGridListModule,
    
    MatProgressSpinnerModule,
    DxDataGridModule,
    DxListModule,
    DxPopupModule,
    DxButtonModule,
    DxTemplateModule
    
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalModule,
   // MatFileUploadModule,
    MsalBroadcastService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
       provide: HTTP_INTERCEPTORS, 
       useClass: NetworkInterceptor,
        multi: true
    },
  ], 
  bootstrap: [AppComponent]
})
export class AppModule { }
//platformBrowserDynamic().bootstrapModule(AppModule);
