// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.img-container {
    width: 40%;
    float: left;
    margin-top: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 640px;
}

input[type="file"] {
    position: relative;
    top: 10px;
}

.delete-ico {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 20px;
    color: #4f4d4d;
    border-radius: 50%;
}

.thumbnail-wraper {
    width: 59%;
    float: right;
    margin-top: 20px;
}

.add-tag {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.bg-color {
    background-color: #fbfbfb;
    min-height: 200px;
}

img {
    height: 80%;
}

.img-align {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 60px;
}

.asset-area {
    height: 370px;
    overflow: hidden;
    overflow-y: scroll;
}

.brdr {
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 4px;
    width: 30.10%;
    height: 100px;
    float: left;
    margin: 10px;
    position: relative;
}

.user-details {
    float: left;
    width: 60%;
    margin-top: 10px;
}

.brdr .user-details li {
    list-style: none;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.font-weight {
    font-weight: bold;
}

.brdr img {
    margin: 10px 10px 0 10px;
    float: left;
}

.selected, .brdr:hover{
    border: 1px solid #3f51b5;
    box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
    -webkit-box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
    -moz-box-shadow: 1px 2px 9px 3px rgb(63 81 181 / 60%);
    border-radius: 4px;
}

.img-description {
    padding: 10px;
    box-sizing: border-box;
    margin: 10px 0;
    position: relative;
    border-top: 1px solid #f1f1f1;
    border-bottom: 3px solid #f1f1f1;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}


::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 10px;
}


`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
