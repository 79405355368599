// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.vdo-container {
    width: 65%;
    float: left;
}
.vdo-wrapper {
    width: 100%;
    margin-top: 20px;
}
.vdo-wrapper img {
    width: 100%;
}
.vdo-list-wrapper {
    width: 33%;
    float: right;
}
.vdo-list {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    background: #F7F6F6;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 3px;
}
.vdo-list .title {
    display: block;
}
.vdo-list img{
    width: 50%;
    float: left;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
