import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {

  audioData = data;

  constructor() { }

  ngOnInit(): void {
  }

}

export const data = [
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  },
  {
    imgSouce: '../../../assets/images/audio.png'
  }
]