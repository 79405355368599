// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-spacer {
    flex: 1 1 auto;
  }

.mat-toolbar-single-row {
    height: 50px !important;
}
.main-header {
  background-color: #165788 !important;
  background-repeat: no-repeat;
}

.alcoa-logo {
  margin-left: 30px;
}

.rt-nav {
    margin: 0 10px;
}

.user {
    background: #fff;
    border-radius: 50%;
    text-align: center;
    vertical-align: bottom;
    font-size: 23px;
    color: #000;
}

.user-info {
    font-weight: normal;
    font-size: 12px;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
