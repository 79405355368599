// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-image {
    height: 70vh;
    background-image: url('404.554a1df103cff2e5.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60vh;
}
`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
