// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sub-heading {
    color: #165788;
    font-size: 12px;
  }

  
.img-container {
    width: 55%;
    float: left;
    /*margin-top: 30px;*/
    border: 1px solid #ccc;
    border-radius: 5px;
    height:532px;
    margin: 6px 6px 6px 6px;
  }
  .file-container {
    width: 55%;
    height:300px;
    margin: 6px 6px 6px 6px;
  }

  .font-weight {
    font-weight: bold;
  }

  .img-align {
    max-width: 100%;
    max-height: 95%;
  }


  .overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
